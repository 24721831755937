import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Timetable } from "./components/timetable"
import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Donate } from "./components/donate";
import { Testimonials } from "./components/testimonials";
import { Education } from "./components/Education";
import { Contact } from "./components/contact";
import { Footer } from "./components/footer";
import {Popups} from "./components/popup";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import AppContext from "./contexts/AppContext";
import 'reactjs-popup/dist/index.css';
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <AppContext>
        <Popups />
        <Navigation />
        <Timetable />
        <Header data={landingPageData.Header} />
        <About data={landingPageData.About} />
        <Services data={landingPageData.Services} />
        <Donate data={landingPageData.Donate} />
        <Testimonials data={landingPageData.Testimonials} />
        <Education data={landingPageData.Team} />
        <Contact data={landingPageData.Contact} />
        <Footer />
      </AppContext>
    </div>
  );
};

export default App;
