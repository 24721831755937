import { useState } from "react";
import emailjs from "emailjs-com";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons'

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm("service_madani", "template_madani", e.target, "zniL1HecqNei7O3pa")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <div id="contact">
        <a className="contactAnchor" id="contactAnchor"></a>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate='false' onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>

            {/* Right Column with Contact Info and Map */}
            <div className="col-md-4">
              <div className="contact-info">
                <h3>Contact Info</h3>
                <div className="contact-item">
                  <a href={props.data ? "https://www.google.com/maps/search/?api=1&query=51.521733338954164%2C-0.650387345179031" : "loading"} target="_blank" rel="noreferrer">
                    <span>
                      <i className="fa fa-map-marker"></i> Address
                    </span>
                    {props.data ? props.data.address : "loading"}
                  </a>
                </div>
                <div className="contact-item">
                  <a href={props.data ? "tel:" + props.data.phone : "loading"} target="_blank" rel="noreferrer">
                    <span>
                      <i className="fa fa-phone"></i> Phone
                    </span>{" "}
                    {props.data ? props.data.phone : "loading"}
                  </a>
                </div>
                <div className="contact-item">
                  <a href={props.data ? "mailto:" + props.data.email : "loading"} target="_blank" rel="noreferrer">
                    <span>
                      <i className="fa fa-envelope-o"></i> Email
                    </span>{" "}
                    {props.data ? props.data.email : "loading"}
                  </a>
                </div>
                <div className="contact-item">
                  <a href={props.data ? props.data.whatsapp : "loading"} target="_blank" rel="noreferrer">
                    <span>
                      <i className="fa fa-whatsapp"></i> Whatsapp
                    </span>{" "}
                    Click to Join our group
                  </a>
                </div>
                <div className="contact-item">
                  <a href={props.data ? props.data.charity : "loading"} target="_blank" rel="noreferrer">
                    <span>
                      <FontAwesomeIcon icon={faHandHoldingDollar} /> Charity
                    </span>{" "}
                    Charity Number: 1079416
                  </a>
                </div>
              </div>
            </div>

            {/* Map Section (In a column beside the contact info) */}
            <div className="col-md-12 mt-4">
              <div className="map-container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4224.832719885643!2d-0.652968022408347!3d51.521769209652085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487664afdaac5947%3A0xbf7bfa0423ab0a2f!2sAl-Madani%20Masjid!5e1!3m2!1sen!2suk!4v1742833131336!5m2!1sen!2suk"
                  width="100%"
                  height="450"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Al-Madani Masjid"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
