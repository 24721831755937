import { GET_POSTERS } from "../graphql/queries/posters";
import { useQuery } from "@apollo/client";

export const usePosters = () => {
  const { loading, error, data } = useQuery(GET_POSTERS);
  // we can change data to a state if we ever wanna setState but I dont think we need to
  return {
    loading,
    data,
    error,
  };
};
