import { useContext } from "react";
import Marquee from "react-fast-marquee";
import { AnnouncementsContext } from "../contexts/announcementsContext";

export const Footer = () => {
  const { data, loading } = useContext(AnnouncementsContext);
  const announcements = !loading && data.allAnnouncement;

  return (
    <div className="navbar navbar-fixed-bottom footer-wrapper">
      <Marquee 
        gradient={false} 
        speed={50} 
        pauseOnHover={true} 
        autoFill={true} 
        style={{ whiteSpace: "nowrap" }}
      >
        {announcements &&
          announcements.map((a, index) => (
            <span key={index} style={{ marginRight: "20px" }}>
              {a.title} &nbsp; * &nbsp;
            </span>
          ))}
      </Marquee>
    </div>
  );
};