export const Donate = (props) => {
  return (
    <div id='donate' className='text-center'>
      <a className="donateAnchor" id="donateAnchor"></a>
      <div className='container'>
        <div className='section-title'>
          <h2>Donate</h2>
          <p>
            The Prophet (ﷺ) said: “Whoever builds a masjid for the sake of Allah, Allah will build for him a house in Paradise.”
          </p>
        </div>
        <div className="donate">
          <iframe src="https://donate.kindlink.com/ABU-HANEEFA-EDUCATIONAL-TRUST/4646" scrolling="no"> <p>Your browser does not support iframes.</p> </iframe>
        </div>
      </div>
    </div>
  )
}
