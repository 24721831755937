export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
          <div className='row mixlr'>
                <iframe src="https://al-madani-masjid.mixlr.com/embed" frameBorder="0" scrolling="no" height="100%" width="100%"></iframe>
            </div>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
