import React from "react";
import { useAnnouncements } from "../hooks/announcements";
import { AnnouncementsContext } from "./announcementsContext";

import { useTimetables } from "../hooks/timetables";
import { TimetablesContext } from "./timetablesContext";

import { PostersContext } from "./postersContext";
import { usePosters } from "../hooks/posters";

import { PopupContext } from "./popupContext";
import { usePopup } from "../hooks/popup";

import { HadeethContext } from "./hadeethContext";
import { useHadeeth } from "../hooks/hadeeth";

const AppContext = ({ children }) => {
  const announcements = useAnnouncements();
  const timetables = useTimetables();
  const posters = usePosters();
  const popup = usePopup();
  const hadeeth = useHadeeth();

  return (
    // consolidate all contexts within AppContext
    <PopupContext.Provider value={popup}>
      <PostersContext.Provider value={posters}>
        <AnnouncementsContext.Provider value={announcements}>
          <TimetablesContext.Provider value={timetables}>
            <HadeethContext.Provider value={hadeeth}>
              {children}
            </HadeethContext.Provider>
          </TimetablesContext.Provider>
        </AnnouncementsContext.Provider>
      </PostersContext.Provider>
    </PopupContext.Provider>
  );
};

export default AppContext;
