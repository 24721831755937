import { gql } from "@apollo/client";

export const GET_TIMETABLES = gql`
    query GetTimetables {
        allTimetable {
            _id
            _createdAt
            _updatedAt
            title
            excel_file {
                asset {
                    url
                }
            }
            pdf_file {
                asset {
                    url
                }
            }
            jummah_1
            jummah_2
            jummah_3
        }
    }
`;