import { GET_HADEETH } from "../graphql/queries/hadeeth";
import { useQuery } from "@apollo/client";

export const useHadeeth = () => {
  const { loading, error, data } = useQuery(GET_HADEETH);
  // we can change data to a state if we ever wanna setState but I dont think we need to
  return {
    loading,
    data,
    error,
  };
};
