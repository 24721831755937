import { gql } from "@apollo/client";

export const GET_ANNOUNCEMENTS = gql`
  query GetAnnouncements {
    allAnnouncement {
      _id
      _createdAt
      _updatedAt
      title
    }
  }
`;
